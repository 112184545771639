import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal,
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Transmissão recebida do controle de solo
        </Header>
        <Body>
          <p>Oh não! Parece que vocês chegaram tarde.</p>
          <p>Os sistemas de oxigênio não foram consertados permanentemente a tempo e o pior aconteceu. Todos os cientistas da Estação Espacial Órion perderam suas vidas e estão agora em meio às estrelas!</p>
          <p>Embora a gente não tenha concluído com sucesso nossa missão, vamos conversar... onde erramos? O que poderíamos ter feito de forma diferente?</p>
          <p>-Controle de solo</p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}