import { SpaceStationMetadata } from './types';

const metadata: SpaceStationMetadata = {
  'andrômeda': {
    location: 'andrômeda',
    message: {
      asset: 'modals/Andromeda_Information_Card.jpg',
      note: "RECURSOS DE RESGATE DISPONÍVEIS NESTA ESTAÇÃO PARA SEREM ADQUIRIDOS VIA PASSARELA ESTELAR",
      technology: "Equipe de manutenção alimentar",
      title: 'Equipe de manutenção alimentar',
      paragraphs:[
        {text: 'A equipe de manutenção alimentar está em outra missão e não retornará a Andrômeda até o dia 14.'}
      ],
      sideNote: "disponível no dia 14",
    }
  },
  'aquário': {
    location: 't3',
    message: {
      asset: 'modals/Aquarius_Information_Card.jpg',
      note: "RECURSOS DE RESGATE DISPONÍVEIS NESTA ESTAÇÃO PARA SEREM ADQUIRIDOS VIA PASSARELA ESTELAR",
      technology: "Equipe de manutenção hidráulica",
      title: 'COMIDA EM ÓRION',
      paragraphs: [
        {text: 'Os sistemas de processamento de comida foram destruídos no momento do acidente.'},
        {text: 'As reservas de comida de emergência estão acabando e a perda de vida começará no dia 24 a não ser que elas sejam consertadas.'},
        {text: 'Uma equipe de manutenção alimentar ficará disponível em Andrômeda.'},
      ],
    }
  },
  'boreal': {
    location: 't5',
    message: {
      asset: 'modals/Borealis_Information_Card.jpg',
      note: "RECURSOS DE RESGATE DISPONÍVEIS NESTA ESTAÇÃO PARA SEREM ADQUIRIDOS VIA PASSARELA ESTELAR",
      technology: "Equipe de manutenção de oxigênio",
      title: 'REPAROS DE O2 EM ÓRION',
      paragraphs: [
        {text: 'A equipe de manutenção de O2 pode restaurar imediatamente e de maneira permanente o oxigênio em Órion assim que chegarem lá.'},
        {text: 'No entanto, eles não podem sair de Boreal enquanto a tecnologia IA de Capricórnio não chegar para os substituir.'},
        {text: 'Sair antes disso irá colocar todos os 200 cientistas em Boreal em risco.'},
      ],
    }
  },
  'capricórnio': {
    location: 't4',
    message: {
      asset: 'modals/Capricorn_Information_Card.jpg',
      note: "RECURSOS DE RESGATE DISPONÍVEIS NESTA ESTAÇÃO PARA SEREM ADQUIRIDOS VIA PASSARELA ESTELAR",
      technology: "Tecnologia IA",
      title: 'FERIDOS EM ÓRION',
      paragraphs: [
        {text: 'Três pessoas foram feridas no momento do acidente.'},
        {text: 'Os feridos estão piorando progressivamente e morrerão no dia 25 sem o tratamento médico avançado.'},
        {text: 'A equipe médica está atualmente em Cassiopeia.'},
      ]
    }
  },
  'cassiopeia': {
    location: 'cassiopeia',
    message: {
      asset: 'modals/Cassiopeia_Information_Card.jpg',
      note: "RECURSOS DE RESGATE DISPONÍVEIS NESTA ESTAÇÃO PARA SEREM ADQUIRIDOS VIA PASSARELA ESTELAR",
      technology: "Equipe médica",
      title: 'FORNECIMENTO DE ÁGUA EM ÓRION',
      paragraphs: [
        {text: 'O sistema de purificação de água desligou durante o acidente.'},
        {text: 'Há reserva de água suficiente para manter os cientistas vivos, mas apenas até o dia 23. A perda de vida começará a ocorrer novamente a não ser que o sistema de água da estação seja consertado permanentemente.'},
        {text: 'Uma equipe de manutenção hidráulica está disponível em Aquário.'},
      ]
    }
  },
  'órion': {
    location: 't2',
    message: {
      asset: 'modals/Orion_Information_Card.jpg',
      title: 'O2 EM ÓRION',
      paragraphs: [
        {text: 'Os cientistas na Estação Espacial Órion estão rapidamente ficando sem oxigênio.'},
        {text: 'As células de substituição de O2 a bordo da sua nave resolverão temporariamente o problema de geração de oxigênio até o dia 21.'},
        {text: 'A perda de vida começará a ocorrer novamente se uma solução permanente de geração de oxigênio não estiver instalada até o dia 21.'},
      ]
    }
  },
  'sagitário': {
    location: 'sagitário',
    message: {
      asset: 'modals/Sagittarius_Information_Card.jpg',
      title: 'DANOS À ESTAÇÃO ESPACIAL ÓRION',
      paragraphs: [
        {text: 'A principal fonte de geração de oxigênio de Órion foi gravemente atingida.'},
        {text: 'Eles têm apenas 6 dias de suprimento de oxigênio antes do início da perda de vidas.'},
        {text: 'Sem os reparos, a geração de oxigênio irá parar completamente em 21 dias.'},
      ]
    }
  },
};

export default metadata;
