import React from 'react';
import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal
} from './modal';
import { StyledExtraModal } from './MessageModal';
import styled from 'styled-components';
import EndGameStatsModal from './EndGameStatsModal';

const SuccessModalBackground = styled(ModalBackground)`
  background-image: url(${process.env.PUBLIC_URL}/game_success_map.png);
  background-size: contain;
`;

export default () => {
  return <SuccessModalBackground>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Bem-vindos de volta a Sagitário!
        </Header>
        <Body>
          <p>Estamos muito animados para ouvir as histórias da sua missão bem-sucedida!</p>
          <p>Quando estiverem prontos, chamem o comandante espacial para parabenizá-los pessoalmente!</p>
          <p>Agradecemos por tudo o que fizeram para resgatar Órion!</p>
          <p>-Controle de solo </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>      
    </BaseModalBackground>
  </SuccessModalBackground>;
}