import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal,
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Atenção, tripulação Gemini.
        </Header>
        <Body>
          <p>Infelizmente, o tempo da sua missão de resgatar Órion acabou. </p>
          <p>Nossa equipe de comando continuará trabalhando para salvar a estação espacial, mas agora precisam de vocês no centro de comando. </p>
          <p>Apertem os cintos: estamos prestes a transportá-los de volta para se encontrarem com o comandante espacial para que possamos relatar sua missão. </p>
          <p>-Controle de solo </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}