import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Missão abandonada!
        </Header>
        <Body>
          <p>Você abandonou sua missão e, sem sua ajuda, tememos que o pior tenha acontecido na Estação Espacial Órion. </p>
          <p>Alerte o comandante espacial para vir e instruir sobre o que fazer a seguir. </p>
          <p>-Controle de solo </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}